<template>
  <searchable-table
    :items="[]"
    :fields="[]"
  >
    <template #modalbutton>
      <app-button
        icon="PlusIcon"
        size="md"
        text="Teklif Ekle"
      />
    </template>
  </searchable-table>
</template>

<script>
export default {
  data() {
    return {
      fields: [],
    }
  },
}
</script>

<style></style>
